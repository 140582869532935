<template>
  <div>
    <header-slot>
      <template #actions>
        <b-row class="w-100 pb-2">
          <b-col>
            <b-button
              v-show="isModuleActualEqualTwo"
              v-if="!isOnlyLead"
              variant="success"
              @click="openModalSearch()"
            >
              <feather-icon
                icon="SearchIcon"
                size="15"
                class="mr-50 text-white"
              />Payments Search
            </b-button>
          </b-col>
        </b-row>
      </template>
    </header-slot>
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: 'paragon-clients-list' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >PARAGON</b-nav-item
      >
      <b-nav-item
        :to="{ name: 'paragon-clients-business-list' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >BUSINESS</b-nav-item
      >
    </b-nav>

    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="$route.name" />
    </b-card>
    <corp-modal
      v-if="showCorp"
      @whenClose="showCorp = false"
      @whenOk="showCorp = false"
    />
    <llc-modal
      v-if="showLlc"
      @whenClose="showLlc = false"
      @whenOk="showLlc = false"
    />
    <modal-search-payment
      v-if="openModalSearchPayment"
      @close="closeModalSearch"
    />
  </div>
</template>

<script>
import chargeBackService from "@/views/commons/components/charge-back_v2/service/charge.back.service";
import ModalSearchPayment from "@/views/commons/components/charge-back_v2/view/modal/ModalSearchPayment.vue";
import CorpModal from "./components/CorpModal.vue";
import LlcModal from "./components/LlcModal.vue";

export default {
  components: {
    CorpModal,
    LlcModal,
    ModalSearchPayment,
  },
  data() {
    return {
      showCorp: false,
      showLlc: false,
      openModalSearchPayment: false,
      isOnlyLead: false,
      moduleActual: null,
      permitStatus: null,
    };
  },
  computed: {
    isModuleActualEqualTwo() {
      return this.permitStatus === 1;
    },
  },
  async created() {
    await Promise.all([this.moduleId()]);
  },
  mounted() {
    this.getAccessModules();
  },
  methods: {
    openModalSearch() {
      this.openModalSearchPayment = true;
    },
    closeModalSearch() {
      this.openModalSearchPayment = false;
    },
    async getAccessModules() {
      try {
        const params = {
          module_actual: this.moduleActual,
        };
        const result = await chargeBackService.getAccessModule(params);
        this.permitStatus = result[0]?.permit_status;
      } catch (error) {
        console.error(error);
      }
    },
    moduleId() {
      this.moduleActual = this.$route.matched[0].meta.module;
    },
    openCorp() {
      this.showCorp = true;
    },
    openLlc() {
      this.showLlc = true;
    },
  },
};
</script>

<style>
</style>
